.footer {
    width: 100%;
    height: 200px;
    background: #b59e1f;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 200px;
  }
  
  .socialMedia svg {
    color: rgb(255, 255, 255);
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: rgb(255, 255, 255);
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }
  }