
.projects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.projects .projectTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.projectList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/* MENU ITEM STYLING */

.projectItem {
  border-radius: 15px;
  width: 300px;
  height: 330px;
  margin: 40px;

  box-shadow: 0px 3px 15px #b59e1f;
  text-align: center;
  background-color:#b59e1f;
}
.projectItem:hover {
  box-shadow: 0px 3px 15px white;
  transition: 0.3s ease-in;
  transform: scale(1.07);
  cursor: pointer;
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projects h1 {
  text-align: center;
  color: #f0d53d; /* Change color to white */
  font-size: 60px;
  font-weight: bolder;
  font-family: Libre Baskerville;
}
.projects h2 {
  text-align: center;
  color: #f0d53d; 
  margin-top: 80px;
  font-weight: bolder;
}
.projectItem h1 {
  font-size: 22px;
  color: white; /* Change color to white */
}
.projectItem p {
  font-size: 14px;
  color: white; /* Change color to white */
}

@media only screen and (max-width: 1300px) {
  .projectList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList {
    width: 100%;
  }
  .projectItem {
    width: 300px;
    height: 300px;
  }
}